<template>
  <div>
    <p class="text-primary-100 font-bold text-normal mb-[10px] mt-[32px]">堂票序號一覽</p>
    <div class="classTicket-use-list">
      <BaseTable :data="list">
        <el-empty
          v-show="list.length === 0"
          slot="empty"
          description="無堂票序號紀錄"
          :image="require('@/assets/icon/nodata/no_data.svg')"
        />
        <BaseElTableColumn
          prop="code"
          label="堂票序號"
          align="center"
          width="160"
        />
        <BaseElTableColumn
          prop="status"
          label="使用狀態"
          align="center"
        >
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">{{ get(classTicketStatusConfig[scope.row.status], 'label') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="newRecordUseCreatedAt"
          label="變更時間"
          align="center"
        />
        <BaseElTableColumn
          prop="newRecordUseType"
          label="變更事件"
          align="center"
        >
          <template slot-scope="scope">
            {{ get(classTicketUseRecordTypeConfig[scope.row.newRecordUseType], 'label') || '-' }}
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
import { classTicketUseRecordTypeConfig, classTicketStatusConfig } from '@/config/classTicket'
export default defineComponent({
  name: 'ClassTicketSerialList',
  props: {
    classTicketRecordSerials: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const list = computed(() => {
      const serialDetails = get(props.classTicketRecordSerials, 'serialDetails')
      if (!serialDetails) return []
      return serialDetails.map(item => {
        const { code, status, newRecordUseType } = item
        const newRecordUseCreatedAt = get(item, 'newRecordUseCreatedAt') ? formatDate(get(item, 'newRecordUseCreatedAt')) : '-'
        return {
          code,
          status,
          newRecordUseCreatedAt,
          newRecordUseType,
        }
      })
    })
    const tagType = (val) => {
      let type = 'info'
      if (val === 'open') type = 'action'
      if (val === 'cancel') type = 'danger'
      if (val === 'overdue') type = 'warning'
      return type
    }

    return { list, classTicketStatusConfig, classTicketUseRecordTypeConfig, get, tagType }
  },
})
</script>

<style scoped lang="scss"></style>
