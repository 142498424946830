<template>
  <div class="classTicket-info">
    <BaseElForm v-if="data" label-width="150px" label-position="left">
      <BaseElFormItem label="堂票編號">
        <div>{{ data.code }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="堂票名稱">
        <div>{{ data.name }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="持有會員">
        <div>{{ get(data,'Member.UserInfo.name') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="堂票狀態">
        <Tag :type="classTicketTagType(data.status)" style="display: inline-flex;">
          {{ statusLabel(data) }}
        </Tag>
      </BaseElFormItem>
      <!-- <BaseElFormItem label="總張數">
        <div>{{ data.availableTimes }}</div>
      </BaseElFormItem> -->
      <BaseElFormItem label="價格">
        <div>{{ data.price }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="有效期限">
        <Tag :type="classTicketIsExpTag(data.isExp)" style="display: inline-flex;">{{ data.isExp ? '有' : '無' }}</Tag>
      </BaseElFormItem>
      <BaseElFormItem label="有效日期">
        {{ dateFormat(data.exp) }}
      </BaseElFormItem>
      <BaseElFormItem label="備註">
        {{ data.note || '-' }}
      </BaseElFormItem>
      <BaseElFormItem label="新增來源">
        {{ mapOrigin(data.origin) }}
      </BaseElFormItem>
      <BaseElFormItem label="建立日期">
        {{ dateFormat(data.createdAt) }}
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { get } from 'lodash'
import MixinFunc from '@/components/MixinFunc.vue'

export default {
  name: 'ClassTicketInfoBlock',
  mixins: [MixinFunc],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mapOrigin () {
      const origins = {
        admin: '會員後台',
        sales: '銷售紀錄',
        give: '會員轉贈',
        gift: '禮物贈送',
        memberStore: '會員商城',
        eventPlaybook: '系統行銷活動發放',
      }
      return (key) => origins[key] || '-'
    },
  },
  methods: {
    get,
    classTicketTagType (val) {
      let type = 'info'
      if (val === 'overdue') type = 'warning'
      if (val === 'open') type = 'action'
      // if (val === 'cancel') type = 'danger'
      return type
    },
    classTicketIsExpTag (val) {
      let type = 'info'
      if (val) type = 'action'
      return type
    },
    statusLabel (data) {
      if (data.usedTimes || data.availableTimes) {
        if (data.usedTimes === data.availableTimes) return '使用完畢'
      }
      if (data.status === 'overdue') return '已過期'
      else if (data.status === 'open') return '可使用'
      else if (data.status === 'complete') return '使用完畢'
      else if (data.status === 'cancel') return '已作廢'
    },
  },
}
</script>

<style scoped lang="scss"></style>
