<template>
  <el-drawer
    title="堂票詳情"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="classTicket-record-detail"
    @close="$emit('close')"
  >
    <div style="padding: 20px">
      <ClassTicketInfoBlock :data="selectClassTicket" />
      <div v-if="get(selectClassTicket, 'status') ==='open' && isMemberProfile" class="flex justify-end">
        <BaseElButton
          plain
          class="btn"
          type=""
          @click="$emit('cancel')"
        >
          作廢整份堂票
        </BaseElButton>
      </div>
      <ClassTicketUseList
        :data="selectClassTicket"
        :classTicketRecordSerials="classTicketRecordSerials"
      />
      <ClassTicketSerialList
        :classTicketRecordSerials="classTicketRecordSerials"
      />
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ClassTicketInfoBlock from './ClassTicketInfoBlock.vue'
import ClassTicketUseList from './ClassTicketUseList.vue'
import ClassTicketSerialList from './ClassTicketSerialList.vue'
import { get } from 'lodash'
import { useRoute } from 'vue-router/composables'
export default defineComponent({
  name: 'ClassTicketRecordDetail',
  components: {
    ClassTicketInfoBlock,
    ClassTicketUseList,
    ClassTicketSerialList,
  },
  props: {
    show: Boolean,
    selectClassTicket: Object,
    classTicketRecordSerials: Object,
  },
  setup (props, { emit }) {
    const route = useRoute()
    // 會員模組 - 堂票紀錄才顯示
    const isMemberProfile = computed(() => route.name === 'MemberProfile')
    return { get, isMemberProfile }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}

</style>
